var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.closeLightBox($event)
        }
      }
    },
    [
      _c(
        "transition",
        {
          attrs: { mode: "out-in", name: "vib-container-transition" },
          on: {
            afterEnter: _vm.enableImageTransition,
            beforeLeave: _vm.disableImageTransition
          }
        },
        [
          _vm.media && _vm.media.length > 0
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.lightBoxShown,
                      expression: "lightBoxShown"
                    }
                  ],
                  ref: "container",
                  staticClass: "vib-container"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "vib-content",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                        }
                      }
                    },
                    [
                      _c(
                        "transition",
                        {
                          attrs: {
                            mode: "out-in",
                            name: _vm.imageTransitionName
                          }
                        },
                        [
                          _vm.currentMedia.type == undefined ||
                          _vm.currentMedia.type == "image"
                            ? _c("img", {
                                key: _vm.currentMedia.src,
                                staticClass: "vib-image",
                                attrs: {
                                  src: _vm.currentMedia.src,
                                  srcset: _vm.currentMedia.srcset || "",
                                  alt: _vm.currentMedia.caption
                                }
                              })
                            : _vm.media[_vm.select].type == "youtube"
                            ? _c("div", { staticClass: "video-background" }, [
                                _c("iframe", {
                                  attrs: {
                                    src:
                                      "https://www.youtube.com/embed/" +
                                      _vm.media[_vm.select].id +
                                      "?showinfo=0",
                                    width: "560",
                                    height: "315",
                                    frameborder: "0",
                                    allowfullscreen: ""
                                  }
                                })
                              ])
                            : _vm.currentMedia.type == "video"
                            ? _c(
                                "video",
                                {
                                  key: _vm.currentMedia.sources[0].src,
                                  ref: "video",
                                  attrs: {
                                    controls: "",
                                    width: _vm.currentMedia.width,
                                    height: _vm.currentMedia.height,
                                    autoplay: _vm.currentMedia.autoplay
                                  }
                                },
                                _vm._l(_vm.currentMedia.sources, function(
                                  source
                                ) {
                                  return _c("source", {
                                    key: source.src,
                                    attrs: {
                                      src: source.src,
                                      type: source.type
                                    }
                                  })
                                }),
                                0
                              )
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.showThumbs
                    ? _c(
                        "div",
                        {
                          staticClass: "vib-thumbnail-wrapper vib-hideable",
                          class: { "vib-hidden": _vm.controlsHidden },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                            },
                            mouseover: function($event) {
                              _vm.interfaceHovered = true
                            },
                            mouseleave: function($event) {
                              _vm.interfaceHovered = false
                            }
                          }
                        },
                        _vm._l(_vm.imagesThumb, function(image, index) {
                          return _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    index >= _vm.thumbIndex.begin &&
                                    index <= _vm.thumbIndex.end,
                                  expression:
                                    "index >= thumbIndex.begin && index <= thumbIndex.end"
                                }
                              ],
                              key:
                                typeof image.thumb === "string"
                                  ? "" + image.thumb + index
                                  : index,
                              class:
                                "vib-thumbnail" +
                                (_vm.select === index ? "-active" : ""),
                              style: {
                                backgroundImage: "url(" + image.thumb + ")"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.showImage(index)
                                }
                              }
                            },
                            [
                              image.type == "video" || image.type == "youtube"
                                ? _vm._t("videoIcon", [_c("VideoIcon")])
                                : _vm._e()
                            ],
                            2
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "vib-footer vib-hideable",
                      class: { "vib-hidden": _vm.controlsHidden },
                      on: {
                        mouseover: function($event) {
                          _vm.interfaceHovered = true
                        },
                        mouseleave: function($event) {
                          _vm.interfaceHovered = false
                        }
                      }
                    },
                    [
                      _vm._t(
                        "customCaption",
                        [
                          _c("div", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showCaption,
                                expression: "showCaption"
                              }
                            ],
                            domProps: {
                              innerHTML: _vm._s(_vm.currentMedia.caption)
                            }
                          })
                        ],
                        { currentMedia: _vm.currentMedia }
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "vib-footer-count" },
                        [
                          _vm._t(
                            "footer",
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.select + 1) +
                                  " / " +
                                  _vm._s(_vm.media.length) +
                                  "\n          "
                              )
                            ],
                            { current: _vm.select + 1, total: _vm.media.length }
                          )
                        ],
                        2
                      )
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.closable
                    ? _c(
                        "button",
                        {
                          staticClass: "vib-close vib-hideable",
                          class: { "vib-hidden": _vm.controlsHidden },
                          attrs: { type: "button", title: _vm.closeText },
                          on: {
                            mouseover: function($event) {
                              _vm.interfaceHovered = true
                            },
                            mouseleave: function($event) {
                              _vm.interfaceHovered = false
                            }
                          }
                        },
                        [_vm._t("close", [_c("CloseIcon")])],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.media.length > 1
                    ? _c(
                        "button",
                        {
                          staticClass: "vib-arrow vib-arrow-left vib-hideable",
                          class: { "vib-hidden": _vm.controlsHidden },
                          attrs: { type: "button", title: _vm.previousText },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.previousImage()
                            },
                            mouseover: function($event) {
                              _vm.interfaceHovered = true
                            },
                            mouseleave: function($event) {
                              _vm.interfaceHovered = false
                            }
                          }
                        },
                        [_vm._t("previous", [_c("LeftArrowIcon")])],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.media.length > 1
                    ? _c(
                        "button",
                        {
                          staticClass: "vib-arrow vib-arrow-right vib-hideable",
                          class: { "vib-hidden": _vm.controlsHidden },
                          attrs: { type: "button", title: _vm.nextText },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.nextImage()
                            },
                            mouseover: function($event) {
                              _vm.interfaceHovered = true
                            },
                            mouseleave: function($event) {
                              _vm.interfaceHovered = false
                            }
                          }
                        },
                        [_vm._t("next", [_c("RightArrowIcon")])],
                        2
                      )
                    : _vm._e()
                ]
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }